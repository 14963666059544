import React, { Component } from 'react'
import SEO from '../../components/Common/SEO'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Common/Header'
import NewsEdit from '../../components/Edit/interfaces/NewsEdit'

export default class news extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoaded: false
        }
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        return (
            <div id="scrollable">
                <Header isTransparent={false} isLoaded={this.state.isLoaded} threshold={0.01}/>
                <SEO
                    title='News about Verse Estate'
                />
                <div className='snap'>
                    <NewsEdit />
                </div>
                <Footer />
            </div>
        )
    }
}
