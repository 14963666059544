import { Link } from 'gatsby-plugin-react-i18next'
import React, { Component } from 'react'
import '../../../styles/scss/news.scss'
import axios from 'axios'
import authHeader from '../../../../service/auth-header'
import ConfirmationModal from '../ConfirmationModal'

export default class NewsEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            page: {
                name: 'news',
                gallery: [],
            },
            confirmationData: null
        }
    }

    componentDidMount = () => {
        this.isIpValid();
    }

    isIpValid = () => {
        axios.get(`https://versemail.azurewebsites.net/user/`).then(response => {
            if (response) {
                if (JSON.parse(localStorage.getItem('user')) && this.isTokenValid()) {
                    this.setState({
                        isLoading: false
                    });

                    this.fetchAllArticles()
                } else {
                    window.open('/', '_self')
                }
            } else {
                window.open('/', '_self')
            }
        }).catch(error => {
            window.open('/', '_self')
        });
    }

    isTokenValid = () => {
        const token = this.getCurrentToken();
        if (!token || token === undefined)
            return false;
        else if (this.parseJwt(token).exp < Date.now() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }

    getCurrentToken = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken)
            return user.accessToken;
    }

    parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return {exp: 0};
        }
    };

    renderArticles = (articles) => {
        if (!articles?.length) return 'No news yet';

        return articles.map((article, index) => {
            return this.renderArticle(article, index)
        })
    }

    renderArticle = (article, index) => {
        if (!article?.isDeleted)
        return (
            <Link to={`/kmUrx/article/${article?.slug}`} className='article' key={index}>
                <div className='image' style={{backgroundImage: `url(${article?.source})`}}/>

                <div className='text'>
                    <h3>
                        {article?.title !== '' ? article?.title : 'Title...' }
                    </h3>

                    <h4>
                        { article?.date }
                    </h4>

                    <p>
                        {article?.firsParagraph !== '' ? article?.firsParagraph : 'Add content...' }
                        {article?.firsParagraph}
                    </p>
                </div>

                {this.renderOptions(article)}
            </Link>
        )
    }

    navigateArticle = (slug) => {
        
    }

    addArticle = () => {
        axios.post(`https://versemail.azurewebsites.net/news/`, {}, { headers: authHeader() }).then(response => {
            if (response?.data?.articles?.length) {
                let articles = response.data?.articles.reverse();
                
                this.setState({
                    page: {
                        ...this.state.page,
                        gallery: articles
                    }
                })

                let article = articles[0]
                window.open(`/kmUrx/article/${article?.slug}`, '_self')
            }
        }).catch(error => {
            alert('error or logged off')
        });
    }

    fetchAllArticles = () => {
        axios.get(`https://versemail.azurewebsites.net/news/all`, { headers: authHeader() }).then(response => {
            if (response?.data?.articles) {
                let articles = response.data?.articles.reverse();

                this.setState({
                    page: {
                        ...this.state.page,
                        gallery: articles
                    }
                })
            }
        }).catch(error => {

        });
    }

    renderAddArticle = () => {
        return (
            <div className='article add' onClick={this.addArticle}>
                <i class='bx bxs-message-square-add' ></i>
            </div>
        )
    }

    cancelAction = () => {
        this.setState({
            confirmationData: null
        })
    }

    handleDelete = (event, selectedArticle) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            confirmationData: {
                message: 'delete this article ?',
                confirmationAction: this.deleteArticle,
                cancelAction: this.cancelAction,
                parameter: selectedArticle?._id
            }
        })
    }

    handlePublish = (event, selectedArticle) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            confirmationData: {
                message: `${selectedArticle?.isPublished ? 'unpublish' : 'publish'} this article ?`,
                confirmationAction: this.togglePublishArticle,
                cancelAction: this.cancelAction,
                parameter: selectedArticle?._id
            }
        })
    }

    deleteArticle = (id) => {
        this.fireOption(id, 'isDeleted')
    }

    togglePublishArticle = (id) => {
        this.fireOption(id, 'isPublished')
    }

    fireOption = (id, type = 'isDeleted') => {
        let updatedArticle, 
        articles = this.state.page?.gallery.map(articleElt => {
            if (articleElt?._id === id) {
                articleElt[type] = !articleElt[type];

                updatedArticle = articleElt

                if (type === 'isPublished' && updatedArticle?.isPublished) {
                    updatedArticle.isDeleted = false
                }
            }
            
            return articleElt
        });

        this.setState({
            page: {
                ...this.state.page,
                gallery: articles
            }
        })

        axios.put(`https://versemail.azurewebsites.net/news/${id}`, {article: updatedArticle}, { headers: authHeader() }).then(response => {
           console.log(response)
        }).catch(error => {
            alert('error or logged off')
        });
    }

    renderOptions = (article) => {
        return (
            <div className='news-options'>
                <i class={article?.isDeleted ? 'bx bx-check' : 'bx bx-trash'} onClick={(e) => this.handleDelete(e, article, 'isDeleted')} />
                <i class={article?.isPublished ? 'bx bx-show' : 'bx bx-hide'}  onClick={(e) => this.handlePublish(e, article, 'isPublished')}/>
            </div>
        )
    }

    switchRender = () => {
        if (this.state.isLoading)
            return (
                <div style={{height: "calc(100vh - 50px)", display: "flex"}}>
                    <h3 style={{margin: "auto", textAlign: 'center'}}>Checking your browser...</h3>
                </div>
            )
        else 
            return this.renderPage()
    }

    renderPage = () => {
        return (
            <div className='news' id='news'>
                <ConfirmationModal
                    confirmationData={this.state.confirmationData}
                />
                {this.renderAddArticle()}
                {this.renderArticles(this.state.page?.gallery)}
                <div className='load-btn'>
                    Load More
                </div>
            </div>
        )
    }
    
    render() {
        return this.switchRender()
    }
}
